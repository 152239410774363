<template>
  <div class="calendar__table">
    <div class="load__container" v-if="loading">
      <v-progress-circular indeterminate color="primary"/>
    </div>
    <header v-show="loaded">
      <milestone v-for="(milestone, index) in calendar"
                 :key="`milestone-${milestone.milestoneSettingsId}`"
                 :first-milestone="calendar[0]"
                 :milestone="milestone"
                 :index="index"
                 :start-x="startX"
                 @click="$emit('display-update', $event)"
      />
    </header>

    <div class="calendar__forms" v-show="loaded">
      <div class="first__row" ref="labelFirstCol"/>
      <div v-for="survey in uniqueSurveys" :key="`label-${survey.code}`">
        {{ survey.label }}
      </div>
    </div>

    <div class="calendar__days" v-show="loaded">
      <calendar-row :columns="calendarSize"
                    :milestones="[]"
                    :is-header-table="true"
                    :days-in-the-past="daysInThePast"
      />
      <calendar-row v-for="survey in uniqueSurveys"
                    :key="`days-${survey.code}`"
                    :code="survey.code"
                    :columns="calendarSize"
                    :milestones="survey.days"
                    :statuses="survey.statuses"
                    :label="survey.label"
                    :days-in-the-past="daysInThePast"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CalendarTable',

  components: {
    CalendarRow: () => import('@/components/GeneralCalendar/CalendarRow'),
    Milestone: () => import('@/components/GeneralCalendar/Milestone'),
  },

  props: {
    patientId: {
      required: true,
    },
  },

  data: () => ({
    forms: [
      {
        id: 0,
        name: 'Questionnaire initial',
        milestones: [0],
      },
      {
        id: 1,
        name: 'Questionnaire de milieu',
        milestones: [28],
      },
      {
        id: 2,
        name: 'Questionnaire de fin',
        milestones: [56],
      },
    ],
    uniqueSurveys: [],
    loading: false,
    loaded: false,
    startX: 0,
  }),

  computed: {
    ...mapGetters([
      'calendar',
      'userSurveys',
    ]),
    calendarSize () {
      const milestoneStart = this.calendar[0]
      const milestoneEnd = this.calendar[(this.calendar.length - 1)]

      return this.getDayDiff(milestoneStart.date, milestoneEnd.date)
    },
    daysInThePast () {
      const milestoneStart = this.calendar[0]
      const diff = this.getDayDiff(milestoneStart.date, new Date())

      return diff < 0 ? 0 : (diff + 1)
    },
  },

  watch: {
    loaded () {
      if (this.loaded) {
        this.$nextTick(() => {
          const { width } = this.$refs.labelFirstCol.getBoundingClientRect()
          this.startX = width
        })
      }
    },
  },

  created: function () {
    this.loading = true
    this.loaded = false
    Promise.all([
      this.loadUserSurveys(this.patientId),
      this.loadSurveyResults(this.patientId),
    ])
           .then(() => {
             const survey_ids = new Set()
             const allSurveys = {}

             this.userSurveys.forEach(daySurveys => {
               daySurveys.surveys.forEach(survey => {
                 let day = daySurveys.day

                 if (!survey_ids.has(survey.id)) {
                   allSurveys[survey.id] = {
                     label: survey.label,
                     code: survey.code,
                     id: survey.id,
                     days: [day],
                     statuses: { [day]: survey.status },
                   }

                   survey_ids.add(survey.id)
                 } else {
                   allSurveys[survey.id].days.push(day)
                   allSurveys[survey.id].statuses[day] = survey.status
                 }
               })
             })

             for (const survey_code of Object.keys(allSurveys)) {
               this.uniqueSurveys.push(allSurveys[survey_code])
             }

             this.loading = false
             this.loaded = true
           })
  },

  methods: {
    ...mapActions([
      'loadSurveyResults',
      'loadUserSurveys',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.calendar__table {
  display: grid;
  grid-template-columns: auto 1fr;
  overflow-x: auto;
  overflow-y: hidden;

  > .load__container {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 1/3;
  }

  header {
    grid-column: 1/3;
    display: flex;
  }

  .calendar__forms {
    > div {
      min-height: 35px;
      display: flex;
      box-sizing: border-box;
      padding: 0 5px;
      align-items: center;
      white-space: nowrap;

      border-bottom: 1px solid black;
      border-right: 1px solid black;
      border-left: 1px solid black;

      &.first__row {
        border-left: none;
        border-right: 1px solid white;
        width: 100%;
      }
    }
  }
}
</style>
